<template>
    <div class="content">
      <div class="content-header">
        <h1 class="content-title">
          Настройки
        </h1>
        <div class="content-header_misc">
          <StaffHeader v-if="tab === 'staff' && ac('settings.settings_staff.edit')" />
          <TransportHeader v-if="tab === 'transport' && ac('settings.settings_transport.edit')" />
          <PromoHeader v-if="tab === 'promo' && ac('settings.settings_promo.edit')" />
          <ExcursionsHeader v-if="tab === 'excursions' && ac('settings.settings_excursions.edit')" />

          <ProductHeader v-if="tab === 'product' && ac('settings.settings_excursions.edit')" />

          <ObjectsHeader v-if="tab === 'objects' && ac('settings.settings_objects.edit')" />
          <PlaceHeader v-if="tab === 'place' && ac('settings.settings_place.edit')" />
          <PointHeader v-if="tab === 'point' && ac('settings.settings_point.edit')" />
          <PriceTypeHeader v-if="tab === 'price_type' && ac('settings.settings_price_type.edit')" />
          <PostsHeader v-if="tab === 'posts' && ac('settings.settings_posts.edit')" />
          <SubdivisionHeader v-if="tab === 'subdivision' && ac('settings.settings_subdivision.edit')" />
        </div>
      </div>

      <div class="line-tabs" id="tabs">
        <router-link v-if="ac('settings.settings_staff')" :to="getTabLink('staff')" v-bind:class="{current: isTab('staff')}">Сотрудники</router-link>
        <router-link v-if="ac('settings.settings_posts')" :to="getTabLink('posts')" v-bind:class="{current: isTab('posts')}">Должности</router-link>
        <router-link v-if="ac('settings.settings_subdivision')" :to="getTabLink('subdivision')" v-bind:class="{current: isTab('subdivision')}">Подразделения</router-link>
        <router-link v-if="ac('settings.settings_transport')" :to="getTabLink('transport')" v-bind:class="{current: isTab('transport')}">Транспорт</router-link>
        <router-link v-if="ac('settings.settings_promo')" :to="getTabLink('promo')" v-bind:class="{current: isTab('promo')}">Промокоды</router-link>
        <router-link v-if="ac('settings.settings_excursions')" :to="getTabLink('excursions')" v-bind:class="{current: isTab('excursions')}">Экскурсии</router-link>

        <router-link v-if="ac('settings.settings_excursions')" :to="getTabLink('product')" v-bind:class="{current: isTab('product')}">Товары</router-link>

        <router-link v-if="ac('settings.settings_objects')" :to="getTabLink('objects')" v-bind:class="{current: isTab('objects')}">Объекты</router-link>
        <router-link v-if="ac('settings.settings_place')" :to="getTabLink('place')" v-bind:class="{current: isTab('place')}">Места посадки</router-link>
        <router-link v-if="ac('settings.settings_point')" :to="getTabLink('point')" v-bind:class="{current: isTab('point')}">Точки продаж</router-link>
        <router-link v-if="ac('settings.settings_price_type')" :to="getTabLink('price_type')" v-bind:class="{current: isTab('price_type')}">Типы цен</router-link>
      </div>

      <div class="line-tab-con">
        <Staff v-if="tab === 'staff' && ac('settings.settings_staff')" />
        <Transport v-if="tab === 'transport' && ac('settings.settings_transport')" />
        <Promo v-if="tab === 'promo' && ac('settings.settings_promo')" />
        <Excursions v-if="tab === 'excursions' && ac('settings.settings_excursions')" />

        <Product v-if="tab === 'product' && ac('settings.settings_excursions')" />

        <Objects v-if="tab === 'objects' && ac('settings.settings_objects')" />
        <Place v-if="tab === 'place' && ac('settings.settings_place')" />
        <Point v-if="tab === 'point' && ac('settings.settings_point')" />
        <PriceType v-if="tab === 'price_type' && ac('settings.settings_price_type')" />
        <Posts v-if="tab === 'posts' && ac('settings.settings_posts')" />
        <Subdivision v-if="tab === 'subdivision' && ac('settings.settings_subdivision')" />

        <e403 v-if="tab === 'staff' && !ac('settings.settings_staff')" />
        <e403 v-if="tab === 'transport' && !ac('settings.settings_transport')" />
        <e403 v-if="tab === 'promo' && !ac('settings.settings_promo')" />
        <e403 v-if="tab === 'excursions' && !ac('settings.settings_excursions')" />
        <e403 v-if="tab === 'objects' && !ac('settings.settings_objects')" />
        <e403 v-if="tab === 'place' && !ac('settings.settings_place')" />
        <e403 v-if="tab === 'point' && !ac('settings.settings_point')" />
        <e403 v-if="tab === 'price_type' && !ac('settings.settings_price_type')" />
        <e403 v-if="tab === 'posts' && !ac('settings.settings_posts')" />
        <e403 v-if="tab === 'subdivision' && !ac('settings.settings_subdivision')" />
      </div>

    </div>
</template>


<script>
    import mixins from '../../../helpers/mixins.js'
    import e403 from '../../../components/e403';
    import Staff from './tabs/Staff';
    import Excursions from './tabs/Excursions';
    import Objects from './tabs/Objects';
    import Promo from './tabs/Promo';
    import Transport from './tabs/Transport';
    import Posts from './tabs/Posts';
    import Subdivision from './tabs/Subdivision';
    import Place from './tabs/Place';
    import Point from './tabs/Point';
    import PriceType from './tabs/PriceType';
    import Product from "./tabs/Product";

    import StaffHeader from './tabs_header/Staff';
    import ExcursionsHeader from './tabs_header/Excursions';
    import ObjectsHeader from './tabs_header/Objects';
    import PromoHeader from './tabs_header/Promo';
    import TransportHeader from './tabs_header/Transport';
    import PostsHeader from './tabs_header/Posts';
    import SubdivisionHeader from './tabs_header/Subdivision';
    import PlaceHeader from './tabs_header/Place';
    import PointHeader from './tabs_header/Point';
    import PriceTypeHeader from './tabs_header/PriceType';
    import ProductHeader from './tabs_header/Product';

    export default {
        mixins: [mixins],

        components: {
          Product,
          ProductHeader,
          e403,
          Posts,
          Subdivision,
          SubdivisionHeader,
          PriceTypeHeader,
          PriceType,
          PostsHeader,
          Staff,
          Excursions,
          Objects,
          Promo,
          Transport,
          StaffHeader,
          ExcursionsHeader,
          ObjectsHeader,
          PromoHeader,
          TransportHeader,
          Place,
          Point,
          PlaceHeader,
          PointHeader
        },

        watch: {
            '$route'() {
                this.tab = this.$route.params.tab || 'staff';
            }
        },

        data () {
            return {
                tab: this.$route.params.tab || 'staff',
                data: null,
            }
        },

        methods: {
            getTabLink(tab) {
                return `/adm/settings/${tab}`;
            },

            isTab(tab) {
                return this.tab === tab;
            },
        }
    }
</script>
