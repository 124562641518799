<template>
  <div>

    <button class="btn btn-primary" v-on:click="newCollection">
      Новая заявка
    </button>
    <Report ref="report" url="collection/report" :def-params="{order: {field: 'collection_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />

      <div v-if="error">
        {{error}}
      </div>

      <div class="full-width-content table-wrapper" v-else>

        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>

            <tr>
              <OrderTd text="№" field="collection_id" width="100" />
              <OrderTd text="Продавец" field="account_id" />
              <OrderTd text="Инкассатор" field="collector_id" />
              <OrderTd text="Дата создания" field="collection_date" />
              <OrderTd text="Дата изменения" field="ts" />
              <OrderTd text="Статус" field="collection_status" />
              <OrderTd class="ar" text="Сумма" field="collection_amount" />
              <td></td>
            </tr>

            <tr>
              <InputFilter name="collection_id" />
              <InputFilter name="account_id"  />
              <InputFilter name="collector_id" />
              <PeriodPickerFilter name="collection_date" />
              <PeriodPickerFilter name="ts" />
              <SelectFilter name="collection_status" :options="[['success', 'Выполнена'], ['pending', 'Ожидает подтверждения']]" track-by="0" label="1"  />
              <td></td>
              <td></td>
            </tr>

            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="16">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="ar">
                  <b>{{formatRUB(report.summary.trx_collection_amount)}}</b>
                </td>
                <td></td>
              </tr>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">

                <td>
                  {{row.account_id}}
                </td>
                <td>
                  {{row.account_title}} — {{row.account_id}}
                  <div class="row-desc">
                    {{ types[row.account_type] }}
                  </div>
                </td>
                <td>
                  {{row.collector_title}} — {{row.collector_id}}
                </td>
                <td>
                  {{convertDate(row.collection_date)}}
                </td>
                <td>
                  {{convertDate(row.ts)}}
                </td>
                <td>
                  <div :class="`status status_${row.collection_status}`">{{data.status[row.collection_status]}}</div>
                </td>
                <td class="ar">
                  {{formatRUB(row.collection_amount)}}
                </td>

                <td>
                  <div class="nav-right">
                    <a class="nav"></a>
                    <div class="nav-con">
                      <a v-on:click.prevent="edit(row)">
                        Редактировать
                      </a>
                    </div>
                  </div>
                </td>

              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
    <Modal ref="editCollection">
      <h2>
        Изменение инкассации №{{ collection.collection_id }}
      </h2>

      <Input name="account_title" text="Продавец" v-model="collection.account_title" readonly="true" />

      <Input type="number" name="salary_amount" text="Сумма" v-model="collection.collection_amount" icon2="₽" />

      <div class="form-submit">
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
        <a href="#" class="btn btn-middle" v-on:click.prevent="cancel">Закрыть</a>
      </div>
    </Modal>
    <Modal ref="createCollection">
      <h2>
        Новая заявка
      </h2>

      <AutosuggestMulti
          ref="account_id"
          :multiple="false"
          v-model="collection.account_data"
          name="account_id"
          text="Продавец"
          url="/accounts/suggest?type=staff&post=2,6&limit=40"
      />
      <AutosuggestMulti
          ref="account_id"
          :multiple="false"
          v-model="collection.collector_data"
          name="account_id"
          text="Инкассатор"
          url="/accounts/suggest?type=staff&post=12&limit=40"
      />

      <Input type="number" name="salary_amount" text="Сумма" v-model="collection.collection_amount" icon2="₽" />

      <div class="form-submit">
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
        <a href="#" class="btn btn-middle" v-on:click.prevent="cancel">Закрыть</a>
      </div>
    </Modal>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import OrderTd from '../../../../components/report/OrderTd';
import PeriodPickerFilter from "@/components/report/PeriodPickerFilter";
import InputFilter from "@/components/report/InputFilter";
import SelectFilter from "@/components/report/SelectFilter";
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import AutosuggestMulti from "@/components/AutosuggestMulti";

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    Input,
    AutosuggestMulti,
    Modal,
    PeriodPickerFilter,
    OrderTd,
    SelectFilter,
    Loading,
    Pager,
    Report
  },


  data() {
    return {
      collection: {
        collection_id: null,
        collector_id: null,
        collector_data: null,
        account_data: null,
        collection_amount: 0
      },
      types: {
        staff: 'Кассир',
        partner: 'Партнёр'
      },
      subdivision: [
        {value: 3, label: 'Анапа'},
        {value: 4, label: 'Проспект'}
      ],

      data: {
        status: {
          success: 'Выполнена',
          pending: 'Ожидает подтверждения'
        },

        object: ['объект', 'объекта', 'объектов'],

        subdivision: [],
        subdivisionTitle: [],
        subdivision_values: [],
        account: [],

      },
      dates: [],
      days: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб'
      ],
      error: null,
      report: null
    }
  },

  methods: {

    cancel() {
      this.collection = {
        collection_id: null,
        collector_id: null,
        collector_data: null,
        account_data: null,
        collection_amount: 0
      }
      this.$refs.createCollection.hide()
      this.$refs.editCollection.hide()
    },

    newCollection() {
      this.$refs.createCollection.show()
    },

    edit(collection) {
      this.collection = collection;
      this.$refs.editCollection.show()
    },

    async save() {

      if (this.collection.collector_data && this.collection.collector_data.value) {
        this.collection.collector_id = this.collection.collector_data.value
      }
      if (this.collection.account_data && this.collection.account_data.value) {
        this.collection.account_id = this.collection.account_data.value
      }

      let res = await Ajax.post(`/collection/save/${this.collection.collection_id || 0}`, this.collection);

      if (res.response) {
        this.$toast.success({content: `Обновлено`});
        this.cancel()
        this.$refs.report.reload()
      }
      if (res.error) {
        this.$toast.error({content: `Ошибка`});
        this.setError(res.error);
      }
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivision_values.push({value: item.subdivision_id, label: item.subdivision_title});
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {
      await Ajax.excursion(`/excursion/${id}/status`, {status: status});
      this.getReport().reload();
    },
  }
}
</script>
