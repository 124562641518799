<template>

  <div class="content">
      <h1 class="content-title">
        Заказы
        <div class="radio" style="margin: 4px 0 0 20px;">
          <label>
            <input type="checkbox" name="font_size" v-model="showSummary">
            <span></span>
            <div>Показать итоги</div>
          </label>
        </div>
      </h1>

    <Report v-if="ac('orders')" ref="report" url="order/report" :def-params="{order: {field: 'order_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>
        <div class="peoples" v-if="showSummary">
          <div>
            <b>{{ report ? report.summary.trx_people_count : 0 }}</b>
            Мест
          </div>
          <div>
            <b>{{ report ? report.summary.trx_count : 0 }}</b>
            Выбрано заказов
          </div>
          <div>
            <b>{{ formatRUB(report ? report.summary.trx_order_sum_all : 0) }}</b>
            Всего
          </div>
          <div>
            <b>{{ formatRUB(report ? report.summary.trx_order_sum_pre : 0) }}</b>
            Сумма предоплат
          </div>
          <div>
            <b>{{ formatRUB(report ? report.summary.trx_order_pay_sum : 0) }}</b>
            Сумма оплаченных
          </div>
          <div>
            <b>{{ formatRUB(report ? report.summary.trx_order_sum_sale : 0) }}</b>
            Сумма скидок
          </div>
        </div>


        <div class="table-filter">

          <div>
            <label class="input-placeholder">Номер заказа</label>
            <InputFilter name="order_id" class="mb14" />

            <label class="input-placeholder">Клиент</label>
            <div class="filter-multi">
              <div>
                <InputFilter name="order_client_title" placeholder="ФИО" filter-type="like" />
              </div>
              <div>
                <InputFilter name="order_client_phone" placeholder="Телефон" filter-type="like" />
              </div>
            </div>
          </div>
          <div>
            <label class="input-placeholder">Рейс</label>
            <div class="filter-multi mb14">
              <div>
                <InputFilter name="order_title" placeholder="Наименование" filter-type="like" />
              </div>
              <div>
                <InputFilter name="flight_id" ref="flight" placeholder="Номер рейса" filter-type="eq" />
              </div>
            </div>


            <label class="input-placeholder">Точка посадки</label>
            <SelectFilter name="order_place" v-if="data.places_f" :options="data.places_f" track-by="value" label="label" />
          </div>
          <div>
            <label class="input-placeholder">Дата отправления</label>
            <PeriodPickerFilter :allow-future="true" name="order_date_ot" class="mb14" />

            <label class="input-placeholder">Дата продажи</label>
            <PeriodPickerFilter name="order_date" />
          </div>

          <div>
            <label class="input-placeholder">Точка продажи</label>
            <SelectFilter name="order_point" ref="point" v-if="data.point_f" :options="data.point_f" track-by="value" label="label" class="mb14" />

            <label class="input-placeholder">Кассир</label>
            <InputFilter name="order_seller" ref="seller" />
          </div>



          <div>
            <label class="input-placeholder">Способ оплаты</label>
            <SelectFilter name="order_pay_type" :options="[['online', 'Онлайн'], ['card', 'Картой'], ['cash', 'Наличка']]" track-by="0" label="1"  class="mb14"  />

            <label class="input-placeholder">Статус заказа</label>
            <SelectFilter name="order_status" :options="[['cancel_refund', 'Отменён, частичный возврат'], ['order', 'Оплачен'], ['pending', 'Ожидает оплаты'], ['waiting_for_capture', 'Оплачен, не подтверждён'], ['reserved', 'Бронь'], ['cancel', 'Отменён'], ['cancel_success', 'Возврат']]" track-by="0" label="1" />
          </div>

          <div>
            <label class="input-placeholder">Транспорт</label>
            <InputFilter name="transport_number" class="mb14"/>

            <a style="margin-top: 20px;" href="/adm/orders" v-if="$route.fullPath !== '/adm/orders'" class="btn btn-primary">Очистить фильтр</a>

          </div>

        </div>

        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>
            <tr>
              <OrderTd text="№" field="order_id"  width="50" />
              <OrderTd text="Рейс" field="order_title" />

              <OrderTd text="Отправление" field="order_date_ot" />
              <OrderTd text="Продажа" field="order_date" />

              <OrderTd text="Клиент" field="account_id" />
              <OrderTd text="Места" field="order_tickets" />

              <OrderTd text="Место посадки" field="order_place" />

              <OrderTd text="Кассир" field="order_seller" />
              <OrderTd text="Итого" field="order_pay_sum" />


              <OrderTd text="Статус" field="order_status" width="100" />
              <td></td>
            </tr>
            </thead>
            <tbody>
            <Loading />
            <tr v-if="report && report.rows_count === 0">
              <td colspan="10">
                Не найдено.
              </td>
            </tr>
            <template v-if="report && report.rows_count > 0">
              <tr v-for="(row, index) in report.rows" v-bind:key="index" class="row" :class="{row_error: !row.order_tickets || row.order_tickets.split(',').length !== (row.order_adult + row.order_children_1 + row.order_children_2 )}">
                <td>{{row.order_id}}</td>
                <td>
                  <a v-on:click.prevent="$refs.flight.setValue(row.flight_id)"> {{ row.flight_id }}</a> —
                  <router-link :to="'/adm/orders/'+row.order_id">{{row.order_title}}</router-link>
                  <div class="row-desc-full">
                    <span v-if="row.transport_number">{{row.transport_number}} — {{row.transport_title}}</span>
                  </div>
                </td>

                <td>
                  {{convertDate(row.order_date_ot, 'DD.MM.YYYY')}}<br>
                  {{convertDate(row.order_date_ot, 'HH:mm')}}
                </td>
                <td>
                  {{convertDate(row.order_date, 'DD.MM.YYYY')}}<br>
                  {{convertDate(row.order_date, 'HH:mm')}}
                </td>
                <td>
                  <router-link :to="'/adm/clients/' + row.account_id + '/general'">{{row.order_client_title || row.account_title}}</router-link>
                  <div class="row-desc">{{formatPhone(row.order_client_phone || row.user_phone)}} </div>
                </td>

                <td>
                  <div class="line-wrap" v-if="row.transport_place_title && row.transport_place_title.length">
                    {{ row.order_tickets_first && row.order_tickets_last ? row.order_tickets_first : ''}}
                    <div>
                      <b>{{ row.order_tickets ? row.order_tickets.split(',').map(i => {return row.transport_place_title[parseInt(i)]}).join(',') : '' }}</b>
                    </div>
                  </div>
                  <div class="line-wrap" v-else>
                    {{row.order_tickets}}
                  </div>


                  <div v-if="!row.order_tickets || row.order_tickets.split(',').length !== (row.order_adult + row.order_children_1 + row.order_children_2 )">
                    <b>выдано {{ row.order_tickets ? row.order_tickets.split(',').length : 0 }} из {{ (row.order_adult + row.order_children_1 + row.order_children_2 ) }} мест</b>
                  </div>
                </td>


                <td>
                  <b> {{ row.order_place_time }} — </b> {{data.places[row.order_place]}}
                </td>

                <td>
                   <b>{{row.order_seller_title}}</b> — <a v-on:click.prevent="$refs.seller.setValue(row.order_seller)">{{row.order_seller}}</a><br>

                  <div class="row-desc-full" v-if="row.order_point > 0">
                     {{row.point_title}} — <a v-on:click.prevent="$refs.point.setValue(row.order_point)">{{row.order_point}}</a>
                  </div>
                  <div class="row-desc-full" v-else>
                      {{ row.order_seller ? row.order_seller_type === 'partner' ? 'Партнёрская продажа' : 'Кассир без точки' : 'Сайт' }}
                  </div>

                </td>


                <td style="white-space: nowrap" v-if="row.order_partial_pay && row.order_pay_sum != row.order_partial_sum">
                  <b style="white-space: nowrap">{{formatRUB(row.order_partial_sum)}} из {{formatRUB(row.order_pay_sum)}}</b> — <span>{{ payType[row.order_pay_type] }}</span><br>
                  {{formatRUB(row.order_sum_all)}}


                  <div class="row-desc" v-if="row.order_partial_pay">
                    частичная оплата: {{formatRUB(row.order_partial_sum_save)}}
                  </div>

                  <div class="row-desc" v-if="row.order_promo">
                    промокод: {{row.order_promo}}
                  </div>
                </td>

                <td style="white-space: nowrap" v-else>
                  <b>{{formatRUB(row.order_pay_sum)}}</b> — <span>{{ payType[row.order_pay_type] }}</span><br>
                  {{formatRUB(row.order_sum_all)}}

                  <div class="row-desc" v-if="row.order_partial_pay">
                    частичная оплата: {{formatRUB(row.order_partial_sum_save)}}
                  </div>

                  <div class="row-desc" v-if="row.order_promo">
                    промокод: {{row.order_promo}}
                  </div>
                </td>

                <td>
                  <div v-if="row.order_partial_pay && row.order_pay_sum != row.order_partial_sum" class="status status_paused">
                    Ожидает полной оплаты
                  </div>
                  <div v-else class="status" v-bind:class="`status_${row.order_status}`">
                    {{data.status[row.order_status]}}
                  </div>
                </td>

                <td>
                  <div class="nav-right" v-if="ac('orders.edit') || ac('orders.status') || ac('orders.view')">
                    <a class="nav"></a>
                    <div class="nav-con">
                      <a v-if="row.order_resp_id" :href="domain+'/i/'+row.order_resp_id" target="_blank">Посмотреть билет</a>
                      <router-link :to="'/adm/orders/'+row.order_id">
                        <template v-if="ac('orders.edit')">
                          Редактировать
                        </template>
                        <template v-else>
                          Просмотр
                        </template>
                      </router-link>

                      <template v-if="ac('orders.status')">
                        <a href="#" v-on:click.prevent="showChangeStatus(row)">Изменить статус</a>
                      </template>
                    </div>
                  </div>
                </td>
              </tr>

            </template>

            </tbody>
          </table>
        </div>
        <Pager />

        <Modal ref="changeStatus">
          <h2>
            Изменение статуса заказ №{{ changeStatusOrder.order_id }}
          </h2>
          <div class="input-group">
            <Select
                v-model="changeStatusOrder.order_status"
                text="Новый статус"
                :options="[
                  ['cancel_refund', 'Отменён, частичный возврат'],
                  ['order', 'Оплачен'],
                  ['waiting_for_capture', 'Оплачен, не подтверждён'],
                  ['reserved', 'Бронь'],
                  ['pending', 'Ожидает оплаты'],
                  ['cancel', 'Отменён'],
                  ['cancel_success', 'Возврат']
              ]"
                track-by="0"
                label="1"
            />
            <div v-if="['cancel_refund'].indexOf(changeStatusOrder.order_status) > -1">
              <Input name="order_refund" text="Сумма возврата" v-model="changeStatusOrder.order_refund_sum" />
            </div>
          </div>

          <div v-if="['cancel_refund', 'cancel'].indexOf(changeStatusOrder.order_status) > -1 && changeStatusOrder.order_seller">
            <div class="radio">
              <label>
                <input type="checkbox" name="save_award" v-model="changeStatusOrder.save_award">
                <span></span>
                <div>Сохранить ЗП у кассира №{{ changeStatusOrder.order_seller }} - {{ changeStatusOrder.order_seller_title }}</div>
              </label>
            </div>
          </div>

          <div class="form-submit">
            <a href="#" class="btn btn-primary" v-on:click.prevent="changeStatus(changeStatusOrder)">Сохранить</a>
            <a href="#" class="btn btn-middle" v-on:click.prevent="hideChangeStatus">Закрыть</a>
          </div>

        </Modal>
      </div>
    </Report>
    <e403 v-else />
  </div>
</template>

<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import Loading from '../../../components/report/Loading';
import Report from '../../../components/report/Report';
import Pager from '../../../components/Pager';
import PeriodPickerFilter from '../../../components/report/PeriodPickerFilter';
import InputFilter from '../../../components/report/InputFilter';
import SelectFilter from '../../../components/report/SelectFilter';
import OrderTd from '../../../components/report/OrderTd';
import e403 from "@/components/e403";
import Modal from "@/components/Modal";
import Select from "@/components/Select";
import Input from "@/components/Input";

export default {
  mixins: [mixins],

  components: {
    Input,
    Select,
    e403,
    Modal,
    InputFilter,
    SelectFilter,
    PeriodPickerFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },

  created() {
    this.emitter.off("updateScheduleReport")
    this.emitter.on("updateScheduleReport", async () => {
     if (this.$refs.report && !this.changeStatusOrder) {
       this.$refs.report.reload()
     }
    })
    this.loadSubdivision();
  },

  data() {
    return {
      showSummary: false,
      seller: null,
      changeStatusOrder: null,
      payType: {
        cash: 'Наличными кассиру',
        card: 'Картой кассиру',
        online: 'Онлайн'
      },
      data: {
        status: {
          'cancel_refund': 'Отменён, частичный возврат',
          'active': 'Активен',
          'paused': 'Заблокирован',
          'order': 'Оплачен',
          'cancel': 'Отменён',
          'cancel_success': 'Возврат',
          'waiting_for_capture': 'Оплачен, не подтверждён',
          'pending': 'Ожидает оплаты'
        },

        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        places: [],
        places_f: [],
        point: [],
        point_f: [],
        subdivision: [],
        subdivisionTitle: []
      },

      error: null,
      report: null
    }
  },

  methods: {

    showChangeStatus(order) {
      this.changeStatusOrder = order;
      this.changeStatusOrder.save_award = this.changeStatusOrder.order_save_award ? true : false
      this.$refs.changeStatus.show()
    },

    hideChangeStatus() {
      this.$refs.changeStatus.hide()
      this.changeStatusOrder = null;
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;
    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      let place = await Ajax.get('/misc/place?query=');
      let point = await Ajax.get('/misc/point?query=');

      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }

      if (place) {
        this.data.places_f = place

        place.forEach((item) => {
          this.data.places[item.value] = item.label;
        });
      }

      if (point) {
        this.data.point_f = [];

        this.data.point_f.push({label: 'Сайт', value: 0})

        point.forEach((item) => {
          this.data.point_f.push(item)
          this.data.point[item.value] = item.label;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }
    },

    async changeStatus(row) {
      row.status = row.order_status;

      if (['cancel_refund', 'cancel'].indexOf(row.order_status) > -1) {
        row.order_save_award = row.save_award
      } else {
        row.order_save_award = 0
      }

      await Ajax.post(`/order/cancel/${row.order_id}`, row);
      this.hideChangeStatus()
    },
  }
}
</script>
