<template>
    <div class="content">
          <div class="content-header">
            <h1 class="content-title">
              Партнёры
            </h1>
            <div class="content-header_misc">
              <router-link v-if="ac('partners.edit')" class="btn btn-primary" :to="`/adm/partners/edit`">Добавить партнёра</router-link>
            </div>
          </div>

      <div class="line-tabs" id="tabs">
        <router-link to="/adm/partners" v-bind:class="{current: isTab('/adm/partners')}">Все партнёры</router-link>
        <router-link to="/adm/partners_out" v-bind:class="{current: isTab('/adm/partners_out')}">Заявки на вывод средств</router-link>
      </div>


          <Report ref="report" url="partners/report" :def-params="{order: {field: 'account_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
            <Loading />
            <div v-if="error">
              {{error}}
            </div>
            <div class="full-width-content table-wrapper" v-else>
              <div class="table-scrollable">
                <table class="table" cellpadding="0" cellspacing="0" width="100%">
                  <thead>
                  <tr>
                    <OrderTd text="№" field="account_id"  width="50" />
                    <OrderTd text="ФИО" field="account_title" />
                    <OrderTd text="Телефон" field="user_phone" />
                    <OrderTd text="Дата регистрации" field="account_regdate" />
                    <OrderTd text="Количество заказов" field="account_order_count" />
                    <OrderTd text="Статус" field="account_status" width="100" />
                    <td></td>
                  </tr>
                  <tr class="filter-row">
                    <InputFilter name="account_id" />
                    <InputFilter name="account_title" filter-type="like" />
                    <InputFilter name="user_phone" filter-type="like" />
                    <PeriodPickerFilter name="account_regdate" />
                    <td></td>
                    <SelectFilter name="account_status" :options="[['active', 'Активен'], ['paused', 'Заблокирован']]" track-by="0" label="1" />
                    <td></td>
                  </tr>
                  </thead>
                  <tbody v-if="report">
                  <tr v-if="report.rows_count === 0">
                    <td colspan="10">
                      Не найдено.
                    </td>
                  </tr>

                  <template v-else>
                    <tr v-for="(row, index) in report.rows" v-bind:key="index">
                      <td>{{row.account_id}}</td>
                      <td>
                        <div class="row-wrap" v-bind:data-title="row.account_title">
                          <div>
                            <router-link :to="'/adm/partners/' + row.account_id + '/general'">{{row.account_title}}</router-link>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="row-wrap" v-bind:data-title="row.user_phone">
                          <div>
                            {{formatPhone(row.user_phone)}}

                          </div>
                        </div>
                      </td>
                      <td>{{convertDate(row.account_regdate)}}</td>
                      <td>
                        {{ row.account_order_count }}
                      </td>

                      <td>
                        <div class="status" v-bind:class="`status_${row.account_status}`">
                          {{data.status[row.account_status]}}
                        </div>
                      </td>


                      <td>
                        <div class="nav-right">
                          <a class="nav"></a>
                          <div class="nav-con">

                            <a href="#" v-on:click.prevent="impersonate(row.user_id)">Войти</a>
                            <hr>

                            <router-link :to="'/adm/partners/' + row.account_id + '/general'">
                              <template v-if="ac('partners.edit')">
                                Редактировать
                              </template>
                              <template v-else>
                                Просмотр
                              </template>
                            </router-link>
                            <a href="#" v-on:click.prevent="changeStatus(row.account_id, 'active')" v-if="row.account_status !== 'active' && ac('partners.status')">Разблокировать партнёра</a>
                            <a href="#" v-on:click.prevent="changeStatus(row.account_id, 'paused')" v-if="row.account_status !== 'paused' && ac('partners.status')">Заблокировать партнёра</a>

                            <a href="#" v-if="ac('partners.delete')" v-on:click.prevent="changeStatus(row.account_id, 'deleted')">Удалить</a>
                          </div>
                        </div>
                      </td>
                    </tr>

                  </template>
                  </tbody>
                </table>
              </div>

              <Pager />
            </div>
          </Report>

    </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import Loading from '../../../components/report/Loading';
import Report from '../../../components/report/Report';
import Pager from '../../../components/Pager';
import InputFilter from '../../../components/report/InputFilter';
import SelectFilter from '../../../components/report/SelectFilter';
import PeriodPickerFilter from '../../../components/report/PeriodPickerFilter';
import OrderTd from '../../../components/report/OrderTd';
import User from "@/user";

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    PeriodPickerFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },

  data() {
    return {
      data: {
        status: {
          'active': 'Активен',
          'paused': 'Заблокирован'
        },
      },
      loading: false,
      error: null,
      report: null
    }
  },

  methods: {

    impersonate(user_id) {
      User.impersonate(user_id)
      window.location.reload()
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    isTab(tab) {

      return this.$route.path === tab;

    },

    async changeStatus(id, status) {
      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/accounts/${id}/status`, {status: status});
        }
      } else {
        await Ajax.post(`/accounts/${id}/status`, {status: status});
      }

      this.getReport().reload();

    },

  }
}
</script>
