<template>
  <div>
    <Report ref="report" url="settings/excursion/report" :def-params="{order: {field: 'excursion_id', order: 'desc'}, filters: {excursion_id: 0}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>

        <div class="table-filter">
          <div>
            <label class="input-placeholder">Подразделение</label>
            <SelectFilter name="subdivision_id" :options="data.subdivision" track-by="subdivision_id" label="subdivision_title" class="m-0" />
          </div>
          <div>
            <label class="input-placeholder">Экскурсия</label>
            <InputFilter name="excursion_title" filter-type="like" />
          </div>
        </div>

        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>

            <tr>
              <OrderTd text="Экскурсия" field="excursion_title" />

              <td v-for="(date, index) in dates" v-bind:key="index">
                <b>{{convertDate(date, 'DD')}}</b><br>
                {{days[convertDate(date, 'd')]}}
              </td>

            </tr>

            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="16">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">

                <td>
                  <div class="row-wrap" v-bind:data-title="row.excursion_title">
                    <div>
                      <router-link :to="'/adm/settings/excursions/edit/' + row.excursion_id">
                        {{row.excursion_title}}
                      </router-link>
                      <div class="row-desc">
                        {{data.subdivisionTitle[row.subdivision_id]}}
                      </div>
                    </div>
                  </div>
                </td>

                <td v-for="(date, index) in dates" v-bind:key="index">
                  <router-link :to="'/adm/flights/' + row.excursion_id + '/' + convertDate(date, 'DD-MM-YYYY') + '/general'">0 / 100</router-link>
                </td>


              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';
import OrderTd from '../../../../components/report/OrderTd';

import moment from 'moment';

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },


  created() {
    this.loadSubdivision();

    let dateArray = [];
    let currentDate = new Date();
    let i = 0;

    while (i < 14) {
      dateArray.push(new Date (currentDate));
      currentDate = moment(currentDate).add(1, 'days');

      i++;
    }
    this.dates = dateArray;

  },

  data() {
    return {
      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },

        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        subdivision: [],
        subdivisionTitle: []

      },
      dates: [],
      days: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб'
      ],
      error: null,
      report: null
    }
  },

  methods: {


    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {
      await Ajax.excursion(`/excursion/${id}/status`, {status: status});
      this.getReport().reload();
    },
  }
}
</script>
