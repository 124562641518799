<template>
    <div class="content">
      <div class="content-header">
        <h1 class="content-title">
          Печатные материалы
        </h1>
      </div>

    <div class="hide-print print-settings">
      <AutosuggestMulti
          ref="account_id"
          :multiple="false"
          v-model="account_data"
          :value="account_data"
          name="account_id"
          text="Партнёр"
          url="/accounts/suggest?type=partner&limit=40"
          v-on:input="changeAccountIds"
      />

      <div class="radio">
        <label>
          <input type="checkbox" name="showAll" v-model="show_a4_v">
          <span></span>
          <div>А4 вертикальная</div>
        </label>
      </div>

      <div class="radio">
        <label>
          <input type="checkbox" name="showAll" v-model="show_a4_x3">
          <span></span>
          <div>А4 х3</div>
        </label>
      </div>
    </div>

      <template v-if="show">
        <div v-for="(a, k) in [account_data]" v-bind:key="k" class="d-flex d-flex-wrap">
          <div class="a4-v pb" v-show="show_a4_v">
            <div class="qr-logo"></div>
            <div class="qr-text">
              Путешествуйте вместе с нами!<br>
              <b class="bt-g">Экскурсии</b>
            </div>
            <div class="qr-body">
              <QRCodeVue3
                  :width="1200"
                  :height="1200"
                  :value="`https://vizit.tours?p=${a.value}`"
                  image="/android-chrome-512x512.png"
                  :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                  :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 30 }"
                  :dotsOptions="{
                    type: 'rounded',
                    color: '#0b0b8f',
                    gradient: {
                      type: 'linear',
                      rotation: 0,
                      colorStops: [
                        { offset: 0, color: '#0b0b8f' },
                        { offset: 1, color: '#0b0b8f' },
                      ],
                    },
                  }"
                  :backgroundOptions="{ color: '#ffffff' }"
                  :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
                  :cornersDotOptions="{ type: undefined, color: '#000000' }"
                  fileExt="png"
                  :download="false"
                  myclass="partner-qur"
                  imgclass="img-qr"
              />
              <div class="qr-manual">
                <p>
                  Наведите камеру  телефона на QR - код или посетите наш сайт <b>vizit.tours</b>,
                  чтобы посмотреть расписание на ближайшие экскурсии.
                </p>
                <p>
                  Укажите промокод: <b>S{{ a.value }}</b> и получите скидку.
                </p>
              </div>
            </div>
            <div>
              <div class="qr-cloud_text">
                Наши направления
              </div>
              <div class="qr-cloud">
                <i v-for="(tag, index) in tags" v-bind:key="index">
                  {{ tag }}
                </i>
              </div>
            </div>
          </div>

          <div class="a4-v_x3" v-show="show_a4_x3">
            <div v-for="n in 3" v-bind:key="n">
              <div class="a4-v">
                <div class="qr-logo"></div>
                <div class="qr-text">
                  Путешествуйте вместе с нами!<br>
                  <b class="bt-g">Экскурсии</b>
                </div>
                <div class="qr-body">
                  <QRCodeVue3
                      :width="1200"
                      :height="1200"
                      :value="`https://vizit.tours?p=${a.value}`"
                      image="/android-chrome-512x512.png"
                      :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                      :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 30 }"
                      :dotsOptions="{
                type: 'rounded',
                color: '#0b0b8f',
                gradient: {
                  type: 'linear',
                  rotation: 0,
                  colorStops: [
                    { offset: 0, color: '#0b0b8f' },
                    { offset: 1, color: '#0b0b8f' },
                  ],
                },
              }"
                      :backgroundOptions="{ color: '#ffffff' }"
                      :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
                      :cornersDotOptions="{ type: undefined, color: '#000000' }"
                      fileExt="png"
                      :download="false"
                      myclass="partner-qur"
                      imgclass="img-qr"
                  />
                  <div class="qr-manual">
                    <p>
                      Наведите камеру  телефона на QR - код или посетите наш сайт <b>vizit.tours</b>,
                      чтобы посмотреть расписание на ближайшие экскурсии.
                    </p>
                    <p>
                      Укажите промокод: <b>S{{ a.value }}</b> и получите скидку.
                    </p>
                  </div>
                </div>
                <div>
                  <div class="qr-cloud_text">
                    Наши направления
                  </div>
                  <div class="qr-cloud">
                    <i v-for="(tag, index) in tags" v-bind:key="index">
                      {{ tag }}
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </template>

    </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import AutosuggestMulti from "@/components/AutosuggestMulti";
import QRCodeVue3 from "qrcode-vue3";

export default {
  mixins: [mixins],

  components: {
    AutosuggestMulti,
    QRCodeVue3
  },

  data() {
    return {
      show: false,
      show_a4_v: true,
      show_a4_x3: true,
      account_data: [],
      tags:[
        'Абрау-Дюрсо',
        'Водопады',
        'Дольмены',
        'Джиппинг',
        'Геленджик',
        'Тур по Черноморскому побережью',
        'Азовское море',
        'Святые места',
        'Сочи',
        'Абхазия',
        'Вечерние шоу',
      ],
      data: {

      },
      loading: false,
      error: null,
      report: null
    }
  },

  methods: {

    changeAccountIds(){
      this.show = false

      setTimeout(() => {
        this.show = true
      }, 1)
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }
    },

    async changeStatus(id, status) {

      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/q/save/${id}`, {
            q_id: id,
            q_status: status
          });
        }
      } else {
        await Ajax.post(`/q/save/${id}`, {
          q_id: id,
          q_status: status
        });
      }

      this.getReport().reload();

    },

  }
}
</script>
