<template>
  <div>
    <Report ref="report" url="settings/excursion/report" :def-params="{order: {field: 'excursion_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>
        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>
            <tr>
              <OrderTd text="№" field="excursion_id"  width="50" />
              <OrderTd text="Наименование" field="excursion_title" />
              <OrderTd text="Город" field="excursion_city" />
              <OrderTd text="Места посадки" field="excursion_place" />

              <OrderTd text="Объекты" field="object_id" />

              <OrderTd text="Полный тариф" field="excursion_price_adult" />
              <OrderTd text="Детский тариф (6-12)" field="excursion_price_children" />
              <OrderTd text="Детский тариф (0-6) - с местом" field="excursion_price_children_free" />
              <OrderTd text="Детский тариф (0-6) - без места" field="excursion_price_children_free2" />

              <OrderTd text="Подразделение" field="subdivision_id" />

              <OrderTd text="Статус" field="excursion_status" width="100" />
              <td></td>
            </tr>
            <tr class="filter-row">
              <InputFilter name="excursion_id" />
              <InputFilter name="excursion_title" filter-type="like" />

              <SelectFilter name="excursion_city" filter-type="in" :options="[[1, 'Анапа'], [2, 'Витязево'], [3, 'Джемете']]" track-by="0" label="1" />

              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <SelectFilter name="subdivision_id" :options="data.subdivision" track-by="subdivision_id" label="subdivision_title" />
              <SelectFilter name="excursion_status" :options="[['active', 'Активна'], ['paused', 'Заблокирована']]" track-by="0" label="1" />
              <td></td>
            </tr>
            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="10">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">
                <td>{{row.excursion_id}}</td>
                <td>
                  <div class="row-wrap" v-bind:data-title="row.excursion_title">
                    <div>
                      <router-link :to="'/adm/settings/excursions/edit/' + row.excursion_id">{{row.excursion_title}}</router-link>
                    </div>
                  </div>
                </td>
                <td>
                  {{row.city_title}}
                </td>
                <td>
                  {{row.excursion_place ? row.excursion_place.length : 0}} {{declination(row.excursion_place ? row.excursion_place.length : 0, data.place)}}
                </td>
                <td>
                  {{row.object_id ? row.object_id.length : 0}} {{declination(row.object_id ? row.object_id.length : 0, data.object)}}
                </td>
                <td>
                  {{formatRUB(row.excursion_price_adult)}}
                  <div v-if="row.excursion_prepay_allow === 'true'" class="row-desc">
                    {{formatRUB(row.excursion_prepay_adult)}}
                  </div>
                </td>
                <td>
                  {{formatRUB(row.excursion_price_children)}}
                  <div v-if="row.excursion_prepay_allow === 'true'" class="row-desc">
                    {{formatRUB(row.excursion_prepay_children)}}
                  </div>
                </td>
                <td>
                  {{formatRUB(row.excursion_price_children_free)}}
                  <div v-if="row.excursion_prepay_allow === 'true'" class="row-desc">
                    {{formatRUB(row.excursion_prepay_children_free)}}
                  </div>
                </td>

                <td>
                  <template v-if="row.excursion_price_children_free2_allow === 'true'" >
                    {{formatRUB(row.excursion_price_children_free2)}}
                    <div v-if="row.excursion_prepay_allow === 'true'" class="row-desc">
                      {{formatRUB(row.excursion_prepay_children_free2)}}
                    </div>
                  </template>
                  <template v-else>
                    Продажа запрещена
                  </template>
                </td>

                <td>
                  {{data.subdivisionTitle[row.subdivision_id]}}
                </td>
                <td>
                  <div class="status" v-bind:class="`status_${row.excursion_status}`">
                    {{data.status[row.excursion_status]}}
                  </div>
                </td>

                <td>
                  <div class="nav-right">
                    <a class="nav"></a>
                    <div class="nav-con">
                      <router-link :to="'/adm/settings/excursions/edit/' + row.excursion_id">
                        <template v-if="ac('settings.settings_excursions.edit')">
                          Редактировать
                        </template>
                        <template v-else>
                          Просмотр
                        </template>
                      </router-link>

                      <router-link :to="'/adm/settings/excursions/copy/' + row.excursion_id" v-if="ac('settings.settings_excursions.edit')">
                        Копировать
                      </router-link>

                      <a href="#" v-on:click.prevent="changeStatus(row.excursion_id, 'active')" v-if="row.excursion_status !== 'active' && ac('settings.settings_excursions.status')">Разблокировать экскурсию</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.excursion_id, 'paused')" v-if="row.excursion_status !== 'paused' && ac('settings.settings_excursions.status')">Заблокировать экскурсию</a>
                      <a href="#" v-if="ac('settings.settings_excursions.delete')" v-on:click.prevent="changeStatus(row.excursion_id, 'deleted')">Удалить</a>
                    </div>
                  </div>
                </td>
              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';
import OrderTd from '../../../../components/report/OrderTd';

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },


  created() {
    this.loadSubdivision();
  },

  data() {
    return {
      data: {

        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },

        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        subdivision: [],
        subdivisionTitle: []

      },
      error: null,
      report: null
    }
  },

  methods: {

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {

      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/settings/excursion/save/${id}`, {
            excursion_id: id,
            excursion_status: status
          });
        }
      } else {
        await Ajax.post(`/settings/excursion/save/${id}`, {
          excursion_id: id,
          excursion_status: status
        });
      }

      this.getReport().reload();
    },
  }
}
</script>
