<template>
  <div class="content">
    <template>
      <h1 class="content-title">
        Аналитика
      </h1>

      <div class="line-tabs" id="tabs">
        <router-link v-if="ac('analytics.analytics_people')" :to="getTabLink('people')" v-bind:class="{current: isTab('people')}">Количество людей</router-link>
        <router-link v-if="ac('analytics.analytics_schedule')" :to="getTabLink('schedule')" v-bind:class="{current: isTab('schedule')}">Расписание</router-link>
        <router-link v-if="ac('analytics.analytics_income')" :to="getTabLink('income')" v-bind:class="{current: isTab('income')}">Прибыль</router-link>
        <router-link v-if="ac('analytics.analytics_costs')" :to="getTabLink('costs')" v-bind:class="{current: isTab('costs')}">Расходы</router-link>
        <router-link v-if="ac('analytics.analytics_kickbacks')" :to="getTabLink('kickbacks')" v-bind:class="{current: isTab('kickbacks')}">Откаты</router-link>
        <router-link v-if="ac('analytics.analytics_time')" :to="getTabLink('time')" v-bind:class="{current: isTab('time')}">Время активных продаж</router-link>
        <router-link v-if="ac('analytics.analytics_weekly')" :to="getTabLink('weekly')" v-bind:class="{current: isTab('weekly')}">Недельный план</router-link>
      </div>

      <div class="line-tab-con">
        <People v-if="tab === 'people' && ac('analytics.analytics_people')" />
        <Schedule v-if="tab === 'schedule' && ac('analytics.analytics_schedule')" />
        <Income v-if="tab === 'income' && ac('analytics.analytics_income')" />
        <Costs v-if="tab === 'costs' && ac('analytics.analytics_costs')" />
        <Kickbacks v-if="tab === 'kickbacks' && ac('analytics.analytics_kickbacks')" />
        <Time v-if="tab === 'time' && ac('analytics.analytics_time')" />
        <Weekly v-if="tab === 'weekly' && ac('analytics.analytics_weekly')" />

        <e403 v-if="tab === 'people' && !ac('analytics.analytics_people')" />
        <e403 v-if="tab === 'schedule' && !ac('analytics.analytics_schedule')" />
        <e403 v-if="tab === 'income' && !ac('analytics.analytics_income')" />
        <e403 v-if="tab === 'costs' && !ac('analytics.analytics_costs')" />
        <e403 v-if="tab === 'kickbacks' && !ac('analytics.analytics_kickbacks')" />
        <e403 v-if="tab === 'time' && !ac('analytics.analytics_time')" />
        <e403 v-if="tab === 'weekly' && !ac('analytics.analytics_weekly')" />
      </div>

    </template>

  </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js'

import e403 from '../../../components/e403';

import People from './tabs/People';
import Schedule from './tabs/Schedule';
import Income from './tabs/Income';
import Costs from './tabs/Costs';
import Kickbacks from './tabs/Kickbacks';
import Time from './tabs/Time';
import Weekly from './tabs/Weekly';

export default {
  mixins: [mixins],

  components: {
    e403,
    People,
    Schedule,
    Income,
    Costs,
    Kickbacks,
    Time,
    Weekly
  },

  watch: {
    '$route'() {
      this.tab = this.$route.params.tab || 'people';
    }
  },

  data () {
    return {
      tab: this.$route.params.tab || 'people',
      data: null,
    }
  },

  methods: {
    getTabLink(tab) {
      return `/adm/analytics/${tab}`;
    },

    isTab(tab) {
      return this.tab === tab;
    },


  }
}
</script>
