<template>
    <div class="content">
      <Staff v-if="tab === 'staff'" />
      <Transport v-if="tab === 'transport'" />
      <Promo v-if="tab === 'promo'" />
      <Excursions v-if="tab === 'excursions'" />
      <Product v-if="tab === 'product'" />
      <Objects v-if="tab === 'objects'" />
      <Posts v-if="tab === 'posts'" />
      <Subdivision v-if="tab === 'subdivision'" />
      <Place v-if="tab === 'place'" />
      <Point v-if="tab === 'point'" />
      <PriceType v-if="tab === 'price_type'" />
      <Bonus v-if="tab === 'bonus'" />
    </div>
</template>


<script>
    import mixins from '../../../helpers/mixins.js'

    import Staff from './tabs_edit/Staff';
    import Excursions from './tabs_edit/Excursions';
    import Objects from './tabs_edit/Objects';
    import Promo from './tabs_edit/Promo';
    import Transport from './tabs_edit/Transport';
    import Product from './tabs_edit/Product';

    import Posts from './tabs_edit/Posts';
    import Subdivision from './tabs_edit/Subdivision';
    import Place from "@/pages/adm/settings/tabs_edit/Place";
    import Point from "@/pages/adm/settings/tabs_edit/Point";
    import Bonus from "@/pages/adm/settings/tabs_edit/Bonus";

    import PriceType from "@/pages/adm/settings/tabs_edit/PriceType";


    export default {
        mixins: [mixins],

        components: {
          Product,
          Point,
          Place,
          Posts,
          PriceType,
          Subdivision,
          Staff,
          Excursions,
          Objects,
          Bonus,
          Promo,
          Transport
        },

        watch: {
            '$route'() {
                this.tab = this.$route.params.tab || 'staff';
            }
        },

        data () {
            return {
                tab: this.$route.params.tab || 'staff',
                data: null,
            }
        },

        methods: {
            getTabLink(tab) {
                return `/adm/settings/${tab}`;
            },

            isTab(tab) {
                return this.tab === tab;
            },
        }
    }
</script>
