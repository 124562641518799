<template>
    <div class="content">
          <div class="content-header">
            <h1 class="content-title">
              Партнёры
            </h1>

          </div>

      <div class="line-tabs" id="tabs">
        <router-link to="/adm/partners" v-bind:class="{current: isTab('/adm/partners')}">Все партнёры</router-link>
        <router-link to="/adm/partners_out" v-bind:class="{current: isTab('/adm/partners_out')}">Заявки на вывод средств</router-link>
      </div>


          <Report ref="report" url="collection/report" :def-params="{order: {field: 'collection_id', order: 'desc'}, filters: {account_type: 'eq|partner'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
            <Loading />
            <div v-if="error">
              {{error}}
            </div>
            <div class="full-width-content table-wrapper" v-else>
              <div class="table-scrollable">
                <table class="table" cellpadding="0" cellspacing="0" width="100%">
                  <thead>
                  <tr>
                    <OrderTd text="№" field="collection_id"  width="50" />
                    <OrderTd text="Наименование партнёра" field="account_title" />
                    <OrderTd text="Дата заявки" field="collection_date" />
                    <OrderTd text="Реквизиты" field="collection_comment" />

                    <OrderTd text="Сумма" field="collection_amount" />

                    <OrderTd text="Статус" field="collection_status" width="100" />
                    <td></td>
                  </tr>

                  </thead>
                  <tbody v-if="report">
                  <tr v-if="report.rows_count === 0">
                    <td colspan="10">
                      Не найдено.
                    </td>
                  </tr>

                  <template v-else>
                    <tr v-for="(row, index) in report.rows" v-bind:key="index">
                      <td>{{row.collection_id}}</td>
                      <td>
                        <div class="row-wrap" v-bind:data-title="row.account_title">
                          <div>
                            <router-link :to="'/adm/partners/' + row.account_id + '/general'">{{row.account_title}} - {{row.collection_id}}</router-link>
                          </div>
                        </div>
                      </td>


                      <td>{{convertDate(row.collection_date)}}</td>
                      <td>
                        {{ row.collection_comment }}
                      </td>
                      <td>{{formatRUB(row.collection_amount)}}</td>
                      <td>
                        <div class="status" v-bind:class="`status_${row.collection_status}`">
                          {{data.status[row.collection_status]}}
                        </div>
                      </td>


                      <td>
                        <div class="nav-right">
                          <a class="nav"></a>
                          <div class="nav-con">
                            <a href="#" v-on:click.prevent="changeStatus(row, 'success')" v-if="row.collection_status !== 'success'">Изменить статус на "выполнена"</a>
                            <a href="#" v-on:click.prevent="changeStatus(row, 'pending')" v-if="row.collection_status !== 'pending' ">Изменить статус на "ожидает подтверждения"</a>
                          </div>
                        </div>
                      </td>
                    </tr>

                  </template>
                  </tbody>
                </table>
              </div>

              <Pager />
            </div>
          </Report>

    </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import Loading from '../../../components/report/Loading';
import Report from '../../../components/report/Report';
import Pager from '../../../components/Pager';
import OrderTd from '../../../components/report/OrderTd';

export default {
  mixins: [mixins],

  components: {

    OrderTd,
    Loading,
    Pager,
    Report
  },

  data() {
    return {
      data: {
        status: {
          'success': 'Выполнена',
          'pending': 'Ожидает подтверждения'
        },
      },
      loading: false,
      error: null,
      report: null
    }
  },

  methods: {


    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    isTab(tab) {

      return this.$route.path === tab;

    },

    async changeStatus(collection, status) {
      await Ajax.post(`/collection/save/${collection.collection_id}`, {
        collection_amount: collection.collection_amount,
        account_id: collection.account_id,
        collection_id: collection.collection_id,
        collection_status: status
      });

      this.getReport().reload();

    },

  }
}
</script>
